import { useUserProfile, useUpdateUserProfile } from '../../services/userService';
import { useUserFeedbackScore } from '../../services/feedbackService';

export const useProfile = (userId) => {
    const {
        data: profile,
        isLoading: isProfileLoading,
        isError: isProfileError,
    } = useUserProfile(userId);

    const {
        mutate: updateProfile,
        isLoading: isUpdating,
    } = useUpdateUserProfile();

    const {
        data: feedbackScoreData,
        isLoading: isFeedbackScoreLoading,
        isError: isFeedbackScoreError,
    } = useUserFeedbackScore(userId);

    console.log(feedbackScoreData)

    return {
        profile: profile || {},
        feedbackScore: feedbackScoreData,
        isLoading: isProfileLoading || isFeedbackScoreLoading,
        isError: isProfileError || isFeedbackScoreError,
        updateProfile: (updatedProfile) => updateProfile({ userId, profileData: updatedProfile }),
        isUpdating,
    };
};