import { useQuery } from 'react-query';
import apiClient from './apiClient';

const fetchUserFeedbackScore = async (userId) => {
    const response = await apiClient.get(`/profile/score/${userId}`, { requiresAuth: true });
    return response.data;
};

export const useUserFeedbackScore = (userId) => {
    return useQuery(
        ['userFeedbackScore', userId],
        () => fetchUserFeedbackScore(userId),
        {
            onError: (error) => {
                console.error('Error fetching user feedback score:', error);
            },
            // You can add more options here as needed
            // For example:
            // staleTime: 5 * 60 * 1000, // 5 minutes
            // cacheTime: 10 * 60 * 1000, // 10 minutes
        }
    );
};