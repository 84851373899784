import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Alert, Badge, Card } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthProvider';
import ListingCarousel from './ListingCarousel';
import ListingDescription from './ListingDescription';
import OfferSection from './OfferSection';
import OwnerActions from '../OwnerActions';
import AcceptedOfferDetails from './AcceptedOfferDetails';
import FeedbackModal from './FeedbackModal';
import {
    useListingDetails,
    useListingOffers,
    useAcceptedOfferDetails,
    useCheckFeedbackSubmitted
} from '../../../services/listingService';
import styles from './ListingDetail.module.css';

const ListingDetail = () => {
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const { id } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();

    const { data: listing, error: listingError, isLoading: isListingLoading, refetch: refetchListingDetails } = useListingDetails(id);
    const { data: offers, refetch: refetchOffers } = useListingOffers(id, { enabled: listing?.status === 'active' });
    const { data: acceptedOffer, refetch: refetchAcceptedOffer } = useAcceptedOfferDetails(id, { enabled: listing?.status === 'completed' });
    const { data: feedbackSubmitted, refetch: refetchFeedbackStatus } = useCheckFeedbackSubmitted(id, user?.id, { enabled: !!user && listing?.status === 'completed' });

    const handleOfferAccepted = async (newAcceptedOffer) => {
        await refetchAcceptedOffer();
        await refetchFeedbackStatus();
    };

    if (listingError) return <Container className="py-5"><Alert variant="danger">Failed to load listing details. Please try again later.</Alert></Container>;
    if (isListingLoading || !listing) return <Container className="py-5"><div className="text-center"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></div></Container>;

    const isOwner = user && listing.user_id === user.id;
    const isCompleted = listing.status === 'completed';
    const isBuyer = acceptedOffer && user && acceptedOffer.user_id === user.id;
    const canLeaveFeedback = isCompleted && user && (isOwner || isBuyer);

    const getStatusBadgeVariant = (status) => {
        switch (status) {
            case 'active':
                return 'success';
            case 'completed':
                return 'primary';
            default:
                return 'secondary';
        }
    };

    const handleFeedbackClick = () => {
        setShowFeedbackModal(true);
    };

    return (
        <Container className={styles.container}>
            <Card className={styles.card}>
                <Card.Body>
                    <h1 className={styles.title}>
                        {listing.item_name}
                        <Badge bg={getStatusBadgeVariant(listing.status)} className={styles.badge}>
                            {listing.status}
                        </Badge>
                    </h1>
                    <Row className={styles.content}>
                        <Col lg={7}>
                            <ListingCarousel imageUrls={listing.image_urls} />
                        </Col>
                        <Col lg={5}>
                            <ListingDescription
                                description={listing.description}
                                priceRange={listing.price_indication}
                                suburb={listing.suburb}
                                city={listing.city}
                            />
                            {isOwner && !isCompleted && (
                                <OwnerActions
                                    listingId={id}
                                    navigate={navigate}
                                />
                            )}
                        </Col>
                    </Row>

                    {listing.status === 'active' && (
                        <div className={styles.offerSection}>
                            <OfferSection
                                isOwner={isOwner}
                                listingId={id}
                                offers={offers || []}
                                setOffers={refetchOffers}
                                setAcceptedOffer={handleOfferAccepted}
                                setListingDetails={refetchListingDetails}
                            />
                        </div>
                    )}

                    {listing.status === 'completed' && acceptedOffer && (
                        <AcceptedOfferDetails
                            offer={acceptedOffer}
                            onLeaveFeedback={handleFeedbackClick}
                            canLeaveFeedback={canLeaveFeedback}
                            feedbackSubmitted={feedbackSubmitted}
                        />
                    )}
                </Card.Body>
            </Card>

            {showFeedbackModal && user && (
                <FeedbackModal
                    show={showFeedbackModal}
                    handleClose={() => setShowFeedbackModal(false)}
                    itemId={id}
                    fromUserId={user.id}
                    toUserId={isOwner && acceptedOffer ? acceptedOffer.supplier_id : listing.user_id}
                    listingName={listing.item_name}
                    onFeedbackSubmitted={() => refetchFeedbackStatus()}
                />
            )}
        </Container>
    );
};

export default ListingDetail;