import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useCreateListing, useCategories } from '../services/listingService';

const PostAd = () => {
    const [formData, setFormData] = useState({
        itemName: '',
        description: '',
        priceRange: '',
        city: '',
        suburb: '',
        categoryId: '',
    });
    const [images, setImages] = useState([]);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const { data: categories = [] } = useCategories();
    const createListingMutation = useCreateListing();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setImages(files);
    };

    const validateForm = () => {
        let tempErrors = {};
        if (!formData.itemName.trim()) tempErrors.itemName = "Item name is required";
        if (!formData.description.trim()) tempErrors.description = "Description is required";
        if (!formData.priceRange.trim()) tempErrors.priceRange = "Price indication is required";
        if (!formData.city.trim()) tempErrors.city = "City is required";
        if (!formData.suburb.trim()) tempErrors.suburb = "Suburb is required";
        if (!formData.categoryId) tempErrors.categoryId = "Category is required";
        if (images.length === 0) tempErrors.images = "At least one image is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => formDataToSend.append(key, formData[key]));
            images.forEach(image => formDataToSend.append('images', image));

            createListingMutation.mutate(formDataToSend, {
                onSuccess: () => {
                    alert('Listing created successfully!');
                    navigate('/my-listings');
                },
                onError: (error) => {
                    console.error('Error creating listing:', error);
                    setErrors({ submit: 'Failed to create listing. Please try again.' });
                },
            });
        }
    };

    return (
        <Container className="mt-5">
            <h2>Post a New Ad</h2>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Item Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="itemName"
                                value={formData.itemName}
                                onChange={handleChange}
                                isInvalid={!!errors.itemName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.itemName}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Select
                                name="categoryId"
                                value={formData.categoryId}
                                onChange={handleChange}
                                isInvalid={!!errors.categoryId}
                            >
                                <option value="">Select a category</option>
                                {categories.map(category => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.categoryId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                </Form.Group>

                <Row>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Price Indication</Form.Label>
                            <Form.Control
                                type="text"
                                name="priceRange"
                                value={formData.priceRange}
                                onChange={handleChange}
                                isInvalid={!!errors.priceRange}
                            />
                            <Form.Control.Feedback type="invalid">{errors.priceRange}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                isInvalid={!!errors.city}
                            />
                            <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Suburb</Form.Label>
                            <Form.Control
                                type="text"
                                name="suburb"
                                value={formData.suburb}
                                onChange={handleChange}
                                isInvalid={!!errors.suburb}
                            />
                            <Form.Control.Feedback type="invalid">{errors.suburb}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group className="mb-3">
                    <Form.Label>Upload Images</Form.Label>
                    <Form.Control
                        type="file"
                        multiple
                        onChange={handleImageChange}
                        isInvalid={!!errors.images}
                    />
                    <Form.Control.Feedback type="invalid">{errors.images}</Form.Control.Feedback>
                </Form.Group>

                {createListingMutation.isLoading && (
                    <div className="text-center mb-3">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )}

                {errors.submit && <Alert variant="danger">{errors.submit}</Alert>}

                <Button variant="primary" type="submit" disabled={createListingMutation.isLoading}>
                    {createListingMutation.isLoading ? 'Posting...' : 'Post Ad'}
                </Button>
            </Form>
        </Container>
    );
};

export default PostAd;