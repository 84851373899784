import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const OfferModal = ({ showModal, offerPrice, setOfferPrice, handleOfferSubmit, handleCloseModal }) => (
    <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
            <Modal.Title>Make an Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group controlId="offerPrice">
                    <Form.Label>Offer Price</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter your offer price"
                        value={offerPrice}
                        onChange={(e) => setOfferPrice(e.target.value)}
                    />
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
                Close
            </Button>
            <Button variant="primary" onClick={handleOfferSubmit}> {/* Pass as reference */}
                Submit Offer
            </Button>
        </Modal.Footer>
    </Modal>
);

export default OfferModal;
