import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useSubmitFeedback } from '../../../services/listingService';

const FeedbackModal = ({ show, handleClose, itemId, fromUserId, toUserId, listingName, onFeedbackSubmitted }) => {
    const [isPositive, setIsPositive] = useState(true);
    const [comment, setComment] = useState('');

    const submitFeedbackMutation = useSubmitFeedback();

    const handleSubmit = (e) => {
        e.preventDefault();

        submitFeedbackMutation.mutate({
            item_id: itemId,
            from_user_id: fromUserId,
            to_user_id: toUserId,
            is_positive: isPositive,
            comment
        }, {
            onSuccess: () => {
                onFeedbackSubmitted();
                handleClose();
            },
            onError: (error) => {
                console.error('Error submitting feedback:', error);
            }
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Leave Feedback for {listingName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {submitFeedbackMutation.isError && (
                    <Alert variant="danger">Failed to submit feedback. Please try again.</Alert>
                )}
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Experience</Form.Label>
                        <Form.Check
                            type="radio"
                            label="Positive"
                            name="feedbackType"
                            checked={isPositive}
                            onChange={() => setIsPositive(true)}
                        />
                        <Form.Check
                            type="radio"
                            label="Negative"
                            name="feedbackType"
                            checked={!isPositive}
                            onChange={() => setIsPositive(false)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={submitFeedbackMutation.isLoading}
                    >
                        {submitFeedbackMutation.isLoading ? 'Submitting...' : 'Submit Feedback'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default FeedbackModal;