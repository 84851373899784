import React from 'react';
import { Table, Button } from 'react-bootstrap';

const OffersTable = ({ offers, onAccept, onDecline }) => (
    <>
        <h5 className="mt-5">Offers Received</h5>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Username</th>
                    <th>Offer Price</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {offers.map((offer) => (
                    <tr key={offer.id}>
                        <td>{offer.username}</td>
                        <td>${offer.price}</td>
                        <td>{offer.status}</td>
                        <td>
                            {offer.status === 'pending' && (
                                <>
                                    <Button
                                        variant="success"
                                        size="sm"
                                        className="mr-2"
                                        onClick={() => onAccept(offer.id, offer.price)}
                                    >
                                        Accept
                                    </Button>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => onDecline(offer.id)}
                                    >
                                        Decline
                                    </Button>
                                </>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </>
);

export default OffersTable;