import { useQuery, useMutation, useQueryClient } from 'react-query';
import apiClient from './apiClient';

// Helper function to handle API errors
const handleApiError = (error, errorMessage) => {
    console.error(errorMessage, error);
    throw error;
};

// Query keys
const keys = {
    userOffers: (userId) => ['userOffers', userId],
    offerDetails: (offerId) => ['offerDetails', offerId],
    userOffer: (listingId, userId) => ['userOffer', listingId, userId],
};

// Query functions
const getUserOffers = (userId) =>
    apiClient.get(`/offers/user/${userId}`, { requiresAuth: true })
        .then(response => response.data)
        .catch(error => handleApiError(error, 'Error fetching user offers:'));

const checkUserOffer = (listingId, userId) =>
    apiClient.get(`/offers/${listingId}/user/${userId}`, { requiresAuth: true })
        .then(response => response.data)
        .catch(error => handleApiError(error, 'Error checking user offer:'));

// Mutation functions
const makeOffer = ({ listingId, price }) =>
    apiClient.post('/offers/offer-item', { itemId: listingId, price }, { requiresAuth: true })
        .then(response => response.data)
        .catch(error => handleApiError(error, 'Error making offer:'));

const acceptOffer = (offerId) =>
    apiClient.post(`/offers/${offerId}/accept`, null, { requiresAuth: true })
        .then(response => response.data)
        .catch(error => handleApiError(error, 'Error accepting offer:'));

const declineOffer = (offerId) =>
    apiClient.post(`/offers/${offerId}/decline`, null, { requiresAuth: true })
        .then(response => response.data)
        .catch(error => handleApiError(error, 'Error declining offer:'));

const deleteOffer = (offerId) =>
    apiClient.delete(`/offers/${offerId}`, { requiresAuth: true })
        .then(response => response.data)
        .catch(error => handleApiError(error, 'Error deleting offer:'));

// Custom hooks
export const useUserOffers = (userId) =>
    useQuery(keys.userOffers(userId), () => getUserOffers(userId));

export const useCheckUserOffer = (listingId, userId) =>
    useQuery(keys.userOffer(listingId, userId), () => checkUserOffer(listingId, userId));

export const useMakeOffer = () => {
    const queryClient = useQueryClient();
    return useMutation(makeOffer, {
        onSuccess: () => {
            queryClient.invalidateQueries('userOffers');
        },
    });
};

export const useAcceptOffer = () => {
    const queryClient = useQueryClient();
    return useMutation(acceptOffer, {
        onSuccess: () => {
            queryClient.invalidateQueries('userOffers');
        },
    });
};

export const useDeclineOffer = () => {
    const queryClient = useQueryClient();
    return useMutation(declineOffer, {
        onSuccess: () => {
            queryClient.invalidateQueries('userOffers');
        },
    });
};

export const useDeleteOffer = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteOffer, {
        onSuccess: () => {
            queryClient.invalidateQueries('userOffers');
        },
    });
};