import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthProvider';
import { useProfile } from './useProfile'; // Assuming we've created this custom hook

const MyProfile = () => {
    const { user } = useAuth();
    const { profile, feedbackScore, isLoading, isError, updateProfile, isUpdating } = useProfile(user.id);
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateProfile({ ...profile, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        updateProfile(profile, {
            onSuccess: () => {
                setSuccess('Profile updated successfully!');
                setIsEditing(false);
            },
            onError: (error) => {
                setError('Failed to update profile. Please try again.');
                console.error('Error updating profile:', error);
            }
        });
    };

    if (isLoading) return <Container className="mt-5"><Alert variant="info">Loading profile...</Alert></Container>;
    if (isError) return <Container className="mt-5"><Alert variant="danger">Error loading profile</Alert></Container>;

    return (
        <Container className="mt-5">
            <h2>My Profile</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        name="username"
                        value={profile.data.username || ''}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        value={profile.data.email || ''}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Feedback Score</Form.Label>
                    <Form.Control
                        type="text"
                        value={feedbackScore.data.score !== null ? `${feedbackScore.data.score}%` : 'Not available'}
                        disabled={true}
                    />
                </Form.Group>
                {isEditing ? (
                    <>
                        <Button variant="primary" type="submit" className="me-2" disabled={isUpdating}>
                            {isUpdating ? 'Saving...' : 'Save Changes'}
                        </Button>
                        <Button variant="secondary" onClick={() => setIsEditing(false)} disabled={isUpdating}>
                            Cancel
                        </Button>
                    </>
                ) : (
                    <Button variant="primary" onClick={() => setIsEditing(true)}>
                        Edit Profile
                    </Button>
                )}
            </Form>
        </Container>
    );
};

export default MyProfile;