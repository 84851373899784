import React, { createContext, useContext, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { verifySession } from '../utils/authUtils';
import apiClient from '../services/apiClient';
import { useLogout } from '../services/authService';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const queryClient = useQueryClient();

    const { isLoading } = useQuery('session', verifySession, {
        onSuccess: (data) => {
            if (data) {
                setUser(data);
            } else {
                setUser(null);
            }
        },
        onError: () => {
            setUser(null);
        }
    });

    const loginMutation = useMutation(
        async ({ identifier, password, isEmail }) => {
            const response = await apiClient.post('/auth/login', {
                identifier,
                password,
                isEmail,
            });
            return response.data;
        },
        {
            onSuccess: (data) => {
                if (data.user && data.token) {
                    localStorage.setItem('token', data.token);
                    setUser(data.user);
                    queryClient.setQueryData('session', data.user);
                } else {
                    throw new Error('Login response is missing user or token');
                }
            },
            onError: (error) => {
                console.error('Login error:', error);
                setUser(null);
                queryClient.setQueryData('session', null);
            }
        }
    );

    const logoutMutation = useLogout();

    const login = async (credentials) => {
        try {
            await loginMutation.mutateAsync(credentials);
            return true; // Indicate successful login
        } catch (error) {
            console.error('Login failed:', error);
            return false; // Indicate failed login
        }
    };

    const logout = () => {
        logoutMutation.mutate(null, {
            onSuccess: () => {
                setUser(null);
                queryClient.setQueryData('session', null);
                localStorage.removeItem('token'); // Ensure token is removed on logout
            },
            onError: (error) => {
                console.error('Logout failed:', error);
            }
        });
    };

    const updateUser = (updatedUserData) => {
        setUser(prevUser => {
            const newUser = { ...prevUser, ...updatedUserData };
            queryClient.setQueryData('session', newUser);
            return newUser;
        });
    };

    if (isLoading) {
        return <div>Loading...</div>; // Optionally show a loading indicator
    }

    return (
        <AuthContext.Provider value={{
            user,
            isAuthenticated: !!user,
            login,
            logout,
            updateUser,
            isLoggingIn: loginMutation.isLoading,
            loginError: loginMutation.error,
            isLoggingOut: logoutMutation.isLoading,
            logoutError: logoutMutation.error
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);