import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './ListingCard.module.css';

const ListingCard = ({ listing }) => {
    const imageUrl = listing.image_urls ? JSON.parse(listing.image_urls)[0] : null;

    return (
        <Card className={styles.card}>
            <div className={styles.imageContainer}>
                {imageUrl && (
                    <img
                        src={imageUrl}
                        alt={listing.item_name}
                        className={styles.cardImage}
                    />
                )}
            </div>
            <Card.Body className={styles.cardBody}>
                <Card.Title className={styles.cardTitle}>{listing.item_name}</Card.Title>
                <Card.Text className={styles.cardText}>{listing.description}</Card.Text>
                <div className={styles.cardFooter}>
                    <Card.Text><strong>Desired Price:</strong> ${listing.price_indication}</Card.Text>
                    <Card.Text><strong>Location:</strong> {listing.suburb}, {listing.city}</Card.Text>
                </div>
            </Card.Body>
            <Link to={`/listing/${listing.id}`} className="stretched-link" />
        </Card>
    );
};

export default ListingCard;