import React, { useState } from 'react';
import { useRegisterUser } from '../../services/authService';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const registerMutation = useRegisterUser();

    const handleRegister = (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            registerMutation.reset();
            registerMutation.setError('Passwords do not match');
            return;
        }

        const userData = { username, email, password };
        registerMutation.mutate(userData);
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <h2 className="mb-4">Register</h2>
                    <Form onSubmit={handleRegister}>
                        <Form.Group controlId="formUsername" className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword" className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formConfirmPassword" className="mb-3">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm your password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100" disabled={registerMutation.isLoading}>
                            {registerMutation.isLoading ? 'Registering...' : 'Register'}
                        </Button>
                    </Form>

                    {registerMutation.isError && (
                        <Alert variant="danger" className="mt-3">
                            {registerMutation.error?.response?.data?.message || registerMutation.error?.message || 'An error occurred. Please try again.'}
                        </Alert>
                    )}

                    {registerMutation.isSuccess && (
                        <Alert variant="success" className="mt-3">
                            Registration successful! Please check your email to verify your account.
                        </Alert>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default Register;