import React from 'react';
import { Carousel } from 'react-bootstrap';
import styles from './ListingDetail.module.css';

const ListingCarousel = ({ imageUrls }) => {
    let images = [];

    try {
        images = JSON.parse(imageUrls);
        if (!Array.isArray(images)) {
            throw new Error('imageUrls is not a valid array');
        }
    } catch (error) {
        console.error('Error parsing imageUrls:', error);
        return <div className={styles.errorMessage}>Error loading images</div>;
    }

    if (images.length === 0) {
        return <div className={styles.noImages}>No images available</div>;
    }

    return (
        <div className={styles.carouselContainer}>
            <Carousel className={styles.listingCarousel}>
                {images.map((imageUrl, index) => (
                    <Carousel.Item key={index}>
                        <div className={styles.imageContainer}>
                            <img
                                className={styles.carouselImage}
                                src={imageUrl}
                                alt={`Listing ${index + 1}`}
                            />
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default ListingCarousel;