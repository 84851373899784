import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeclineOfferConfirmationModal = ({ show, onHide, onConfirm }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Offer Decline</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to decline this offer?</p>
                <p>This action cannot be undone.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="danger" onClick={onConfirm}>Decline Offer</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeclineOfferConfirmationModal;