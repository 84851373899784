import React, { useState } from 'react';
import OfferModal from '../OfferModal';
import OffersTable from '../OffersTable';
import AcceptOfferConfirmationModal from '../AcceptOfferConfirmationModal';
import DeclineOfferConfirmationModal from '../DeclineOfferConfirmationModal';
import RemoveOfferConfirmationModal from '../RemoveOfferConfirmationModal';
import { Button, Alert } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthProvider';
import {
    useCheckUserOffer,
    useMakeOffer,
    useAcceptOffer,
    useDeclineOffer,
    useDeleteOffer
} from '../../../services/offerService';

const OfferSection = ({ isOwner, listingId, offers, setOffers, setAcceptedOffer, setListingDetails }) => {
    const [showModal, setShowModal] = useState(false);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
    const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
    const [showDeclineConfirmation, setShowDeclineConfirmation] = useState(false);
    const [offerPrice, setOfferPrice] = useState('');
    const [selectedOfferId, setSelectedOfferId] = useState(null);
    const { user } = useAuth();

    const {
        data: userOffer,
        isLoading: isCheckingOffer,
        error: checkOfferError,
        refetch: refetchUserOffer
    } = useCheckUserOffer(listingId, user?.id);
    const makeOfferMutation = useMakeOffer();
    const acceptOfferMutation = useAcceptOffer();
    const declineOfferMutation = useDeclineOffer();
    const deleteOfferMutation = useDeleteOffer();

    const handleOfferSubmit = async () => {
        try {
            const newOffer = await makeOfferMutation.mutateAsync({ listingId, price: offerPrice });
            setOffers(prevOffers => [...prevOffers, newOffer]);
            setShowModal(false);
            setOfferPrice('');
            // Refetch the user offer to update the UI
            await refetchUserOffer();
        } catch (error) {
            console.error("Error submitting offer:", error);
        }
    };

    const handleRemoveOfferClick = () => {
        setShowRemoveConfirmation(true);
    };

    const handleRemoveOfferConfirm = async () => {
        try {
            if (userOffer && userOffer.offerId) {
                await deleteOfferMutation.mutateAsync(userOffer.offerId);
                setOffers(prevOffers => prevOffers.filter(offer => offer.id !== userOffer.id));
            }
            setShowRemoveConfirmation(false);
            await refetchUserOffer();
        } catch (error) {
            console.error("Error removing offer:", error);
        }
    };

    const handleAcceptOfferClick = (offerId, offerPrice) => {
        setSelectedOfferId(offerId);
        setOfferPrice(offerPrice);
        setShowAcceptConfirmation(true);
    };

    const handleDeclineOfferClick = (offerId) => {
        setSelectedOfferId(offerId);
        setShowDeclineConfirmation(true);
    };

    const handleAcceptOffer = async () => {
        try {
            await acceptOfferMutation.mutateAsync(selectedOfferId);
            const acceptedOffer = offers.find(offer => offer.id === selectedOfferId);
            setAcceptedOffer(acceptedOffer);
            setOffers(prevOffers => prevOffers.map(offer =>
                offer.id === selectedOfferId ? { ...offer, status: 'accepted' } : { ...offer, status: 'declined' }
            ));
            setShowAcceptConfirmation(false);
            setListingDetails();
            await refetchUserOffer();
        } catch (error) {
            console.error("Error accepting offer:", error);
        }
    };

    const handleDeclineOffer = async () => {
        try {
            await declineOfferMutation.mutateAsync(selectedOfferId);
            setOffers(prevOffers => prevOffers.map(offer =>
                offer.id === selectedOfferId ? { ...offer, status: 'declined' } : offer
            ));
            setShowDeclineConfirmation(false);
            // Refetch the user offer to update the UI
            await refetchUserOffer();
        } catch (error) {
            console.error("Error declining offer:", error);
        }
    };

    if (isCheckingOffer) return <p>Loading...</p>;

    return (
        <>
            {checkOfferError && <Alert variant="danger">Failed to check existing offer. Please try again.</Alert>}

            {!isOwner && (!userOffer || !userOffer.hasMadeOffer) && (
                <Button variant="primary" onClick={() => setShowModal(true)}>
                    Make an Offer
                </Button>
            )}

            {!isOwner && userOffer && userOffer.hasMadeOffer && (
                <div>
                    <p>Your current offer: ${userOffer.offerPrice}</p>
                    <Button variant="danger" onClick={handleRemoveOfferClick}>
                        Remove Offer
                    </Button>
                </div>
            )}

            <OfferModal
                showModal={showModal}
                offerPrice={offerPrice}
                setOfferPrice={setOfferPrice}
                handleOfferSubmit={handleOfferSubmit}
                handleCloseModal={() => setShowModal(false)}
            />

            <RemoveOfferConfirmationModal
                show={showRemoveConfirmation}
                handleClose={() => setShowRemoveConfirmation(false)}
                handleConfirm={handleRemoveOfferConfirm}
            />

            {isOwner && (
                <OffersTable
                    offers={offers}
                    onAccept={handleAcceptOfferClick}
                    onDecline={handleDeclineOfferClick}
                />
            )}

            <AcceptOfferConfirmationModal
                show={showAcceptConfirmation}
                onHide={() => setShowAcceptConfirmation(false)}
                onConfirm={handleAcceptOffer}
                offerPrice={offerPrice}
            />

            <DeclineOfferConfirmationModal
                show={showDeclineConfirmation}
                onHide={() => setShowDeclineConfirmation(false)}
                onConfirm={handleDeclineOffer}
            />
        </>
    );
};

export default OfferSection;