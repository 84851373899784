import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Listings from './components/Listings/Listings';
import PostAd from './components/PostAd';
import About from './components/About';
import Login from './components/auth/Login';
import VerifyEmail from './components/auth/VerifyEmail';
import Register from './components/auth/Register';
import ListingDetail from './components/Listings/ListingDetail/ListingDetail';
import { AuthProvider } from './contexts/AuthProvider';
import MyOffers from './components/profile/MyOffers';
import withAuth from './hooks/useAuth';
import MyListings from './components/Listings/MyListings';
import MyProfile from './components/profile/MyProfile';
import './App.css';
import "./scss/custom.scss"


const ProtectedPostAd = withAuth(PostAd);
const ProtectedListing = withAuth(ListingDetail);

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Listings />} />
          <Route path="/post-ad" element={<ProtectedPostAd />} />
          <Route path="/login" element={<Login />} />
          <Route path="/my-offers" element={<MyOffers />} />
          <Route path="/my-listings" element={<MyListings />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verifyemail" element={<VerifyEmail />} />
          <Route path="/profile" element={<MyProfile />} />
          <Route path="/about" element={<About />} />
          <Route path="/listing/:id" element={<ProtectedListing />} />
        </Routes>
      </div>
    </AuthProvider>
  );
}

function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('Service Worker registered successfully:', registration.scope);
        })
        .catch(error => {
          console.log('Service Worker registration failed:', error);
        });
    });
  }
}

export default function AppWrapper() {
  React.useEffect(() => {
    registerServiceWorker();
  }, []);

  return (
    <Router>
      <App />
    </Router>
  );
}