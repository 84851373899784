import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthProvider';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Login = () => {
    const [identifier, setIdentifier] = useState(''); // For both email and username
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const { login } = useAuth();

    const from = location.state?.from?.pathname || '/';

    const handleLogin = async (e) => {
        e.preventDefault();

        const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(identifier); // Check if the input is an email

        const success = await login({
            identifier,
            password,
            isEmail,
        });

        if (success) {
            navigate(from, { replace: true });  // Redirect to the intended page
        } else {
            setErrorMsg('Login failed. Please check your credentials.');
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <h2 className="mb-4">Login</h2>
                    <Form onSubmit={handleLogin}>
                        <Form.Group controlId="formIdentifier" className="mb-3">
                            <Form.Label>Email or Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your email or username"
                                value={identifier}
                                onChange={(e) => setIdentifier(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword" className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100">
                            Login
                        </Button>
                    </Form>
                    <div className="mt-4 text-center">
                        <p>Don't have an account? <Link to="/register">Register here</Link></p>
                    </div>

                    {errorMsg && (
                        <Alert variant="danger" className="mt-3">
                            {errorMsg}
                        </Alert>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
