import { useQuery, useMutation, useQueryClient } from 'react-query';
import apiClient from './apiClient';

// Fetch listing details
export const useListingDetails = (id) => {
    return useQuery(['listingDetails', id], async () => {
        const response = await apiClient.get(`/listings/${id}`);
        return response.data.data;
    });
};

// Fetch offers for a listing
export const useListingOffers = (listingId) => {
    return useQuery(['listingOffers', listingId], async () => {
        const response = await apiClient.get(`/offers/listing/${listingId}/owner-offers`, { requiresAuth: true });
        return response.data.offers;
    });
};

// Fetch accepted offer details
export const useAcceptedOfferDetails = (listingId) => {
    return useQuery(['acceptedOffer', listingId], async () => {
        const response = await apiClient.get(`/offers/listing/${listingId}/accepted`, { requiresAuth: true });
        return response.data;
    });
};

// Create a new listing
export const useCreateListing = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (listingData) => {
            const response = await apiClient.post('/listings/post-ad', listingData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                requiresAuth: true,
            });
            return response.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('listings');
            },
        }
    );
};

// Delete a listing
export const useDeleteListing = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (id) => {
            const response = await apiClient.patch(`/listings/${id}/delete`, null, { requiresAuth: true });
            return response.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('listings');
            },
        }
    );
};


// Fetch user listings
export const useUserListings = (userId) => {
    return useQuery(['userListings', userId], async () => {
        const response = await apiClient.get(`/listings/user/${userId}`, { requiresAuth: true });
        return response.data;
    });
};

// Fetch categories
export const useCategories = () => {
    return useQuery('categories', async () => {
        const response = await apiClient.get('/categories');
        if (response.data && !response.data.error) {
            return response.data.data;
        } else {
            throw new Error('Failed to fetch categories');
        }
    });
};

// Check user offer
export const useCheckUserOffer = (listingId, userId) => {
    return useQuery(['userOffer', listingId, userId], async () => {
        const response = await apiClient.get(`/offers/${listingId}/user/${userId}`);
        return response.data;
    });
};

// Submit feedback
export const useSubmitFeedback = () => {
    return useMutation(async (feedbackData) => {
        const response = await apiClient.post('/feedback/submit', feedbackData, { requiresAuth: true });
        return response.data;
    });
};

// Check if feedback is submitted
export const useCheckFeedbackSubmitted = (listingId, fromUserId) => {
    return useQuery(['feedbackSubmitted', listingId, fromUserId], async () => {
        const response = await apiClient.get(`/feedback/check/${listingId}/${fromUserId}`, { requiresAuth: true });
        return response.data.submitted;
    });
};