import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const AcceptOfferConfirmationModal = ({ show, onHide, onConfirm, offerPrice }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Offer Acceptance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to accept this offer of ${offerPrice}?</p>
                <p>Once accepted, other offers will be deleted and the listing will be concluded.</p>
                <p><strong>Warning:</strong> Accept this offer only if you intend to promptly make contact with the person from whom you are accepting the offer, to complete the trade.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="primary" onClick={onConfirm}>Accept Offer</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AcceptOfferConfirmationModal;
