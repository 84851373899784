import { useMutation } from 'react-query';
import apiClient from './apiClient';

const logoutUser = async () => {
    await apiClient.post('/auth/logout');
    localStorage.removeItem('token');
    // Clear any other stored user data
};

export const useLogout = () => {
    return useMutation(logoutUser, {
        onError: (error) => {
            console.error('Logout error:', error);
        },
        // You can add more options here as needed
    });
};

const registerUserFn = async (userData) => {
    const response = await apiClient.post('/auth/register', userData);
    return response.data;
};

export const useRegisterUser = () => {
    return useMutation(registerUserFn, {
        // You can add options here if needed
    });
};

const verifyEmailFn = async (token) => {
    const response = await apiClient.get(`/auth/verify-email?token=${token}`);
    return response.data;
};

export const useVerifyEmail = () => {
    return useMutation(verifyEmailFn, {
        onError: (error) => {
            console.error('Error verifying email:', error);
        },
        // You can add more options here as needed
    });
};