import React, { useState } from 'react';
import { Container, Button, Spinner, Collapse } from 'react-bootstrap';
import styles from './Categories.module.css';

const Categories = ({ categories, onSelectCategory, selectedCategory, isLoading, error }) => {
    const [open, setOpen] = useState(false);

    if (isLoading) return <Container className={styles.categoriesContainer}><Spinner animation="border" /></Container>;
    if (error) return <Container className={styles.categoriesContainer}><p className="text-danger">{error}</p></Container>;
    if (!categories || categories.length === 0) return null;

    return (
        <Container className={styles.categoriesContainer}>
            <Button
                onClick={() => setOpen(!open)}
                aria-controls="category-collapse"
                aria-expanded={open}
                className={styles.categoryToggle}
            >
                {open ? 'Hide Categories' : 'Show Categories'}
            </Button>
            <Collapse in={open}>
                <div id="category-collapse" className={styles.categoryGrid}>
                    {categories.map((category) => (
                        <Button
                            key={category.id}
                            variant={selectedCategory === category.id ? 'primary' : 'outline-primary'}
                            onClick={() => {
                                onSelectCategory(category.id);
                                setOpen(false);
                            }}
                            className={styles.categoryButton}
                        >
                            {category.name} ({category.listing_count})
                        </Button>
                    ))}
                </div>
            </Collapse>
        </Container>
    );
};

export default Categories;