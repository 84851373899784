import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { useDeleteListing } from '../../services/listingService';
import { useQueryClient } from 'react-query';

const OwnerActions = ({ listingId, navigate }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const deleteMutation = useDeleteListing();
    const queryClient = useQueryClient();

    const handleDelete = async () => {
        try {
            await deleteMutation.mutateAsync(listingId);
            queryClient.invalidateQueries('userListings'); // Invalidate the userListings query
            navigate('/');
        } catch (error) {
            console.error('Failed to delete listing:', error);
            // You might want to show an error message to the user here
        }
    };

    return (
        <>
            <Button
                variant="danger"
                onClick={() => setShowDeleteModal(true)}
                disabled={deleteMutation.isLoading}
            >
                {deleteMutation.isLoading ? 'Deleting...' : 'Delete Listing'}
            </Button>

            <DeleteConfirmationModal
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                handleConfirm={handleDelete}
                isDeleting={deleteMutation.isLoading}
            />
        </>
    );
};

export default OwnerActions;