import React from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';

const AcceptedOfferDetails = ({ offer, onLeaveFeedback, canLeaveFeedback, feedbackSubmitted }) => {
    return (
        <Card className="mb-4">
            <Card.Header as="h5" className="bg-success text-white">Accepted Offer Details</Card.Header>
            <Card.Body>
                <Row className="align-items-center">
                    <Col md={8}>
                        <p className="mb-2"><strong>Offer Amount:</strong> ${offer.price}</p>
                        <p className="mb-2"><strong>Buyer Email:</strong> {offer.supplier_email}</p>
                        <p className="mb-0"><strong>Offer Accepted On:</strong> {new Date(offer.updated_at).toLocaleDateString()}</p>
                    </Col>
                    <Col md={4} className="text-md-end mt-3 mt-md-0">
                        {canLeaveFeedback && !feedbackSubmitted && (
                            <Button
                                variant="warning"
                                size="lg"
                                onClick={onLeaveFeedback}
                                className="pulse-button"
                            >
                                Leave Feedback
                            </Button>
                        )}
                        {feedbackSubmitted && (
                            <Button
                                variant="success"
                                size="lg"
                                disabled
                            >
                                Feedback Submitted
                            </Button>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default AcceptedOfferDetails;