import { useQuery, useMutation, useQueryClient } from 'react-query';
import apiClient from './apiClient';

// Function to fetch user profile
const fetchUserProfile = async (userId) => {
    const response = await apiClient.get(`/profile/${userId}`, { requiresAuth: true });
    return response.data;
};

// Function to update user profile
const updateUserProfileFn = async ({ userId, profileData }) => {
    const response = await apiClient.put(`/profile/${userId}`, profileData, { requiresAuth: true });
    return response.data;
};

// Hook to get user profile
export const useUserProfile = (userId) => {
    return useQuery(['userProfile', userId], () => fetchUserProfile(userId), {
        onError: (error) => {
            console.error('Error fetching user profile:', error);
        },
    });
};

// Hook to update user profile
export const useUpdateUserProfile = () => {
    const queryClient = useQueryClient();

    return useMutation(updateUserProfileFn, {
        onSuccess: (data, variables) => {
            // Invalidate and refetch the user profile query
            queryClient.invalidateQueries(['userProfile', variables.userId]);
        },
        onError: (error) => {
            console.error('Error updating user profile:', error);
        },
    });
};