import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useVerifyEmail } from '../../services/authService';

function VerifyEmail() {
    const navigate = useNavigate();
    const location = useLocation();
    const verifyEmailMutation = useVerifyEmail();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (!token) {
            verifyEmailMutation.setError(new Error('No verification token found'));
            return;
        }

        if (!verifyEmailMutation.isLoading && !verifyEmailMutation.isSuccess && !verifyEmailMutation.isError) {
            verifyEmailMutation.mutate(token);
        }
    }, [location, verifyEmailMutation]);

    useEffect(() => {
        if (verifyEmailMutation.isSuccess) {
            const timer = setTimeout(() => {
                navigate('/login');
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [verifyEmailMutation.isSuccess, navigate]);

    if (verifyEmailMutation.isLoading) {
        return <div>Verifying your email...</div>;
    }

    if (verifyEmailMutation.isSuccess) {
        return <div>Email verified successfully! Redirecting to login...</div>;
    }

    if (verifyEmailMutation.isError) {
        return <div>Email verification failed. Please try again or contact support. Error: {verifyEmailMutation.error.message}</div>;
    }

    return <div>Preparing to verify email...</div>;
}

export default VerifyEmail;