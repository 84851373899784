import apiClient from '../services/apiClient';
import { jwtDecode } from 'jwt-decode';

export const isTokenExpired = (token) => {
    try {
        const decoded = jwtDecode(token);
        const now = Date.now().valueOf() / 1000;
        return decoded.exp < now;
    } catch (e) {
        return true;
    }
};

export const refreshToken = async () => {
    try {
        const response = await apiClient.post('/auth/refresh-token');
        const newToken = response.data.token;
        localStorage.setItem('token', newToken);
        return newToken;
    } catch (error) {
        console.error('Token refresh failed:', error);
        localStorage.removeItem('token');
        return null;
    }
};

export const verifySession = async () => {
    let token = localStorage.getItem('token');

    if (!token || isTokenExpired(token)) {
        token = await refreshToken();
        if (!token) {
            return null;
        }
    }

    try {
        const response = await apiClient.get('/auth/verify-session', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.user;
    } catch (error) {
        console.error('Session verification failed:', error);
        return null;
    }
};
