import React from 'react';
import { useAuth } from '../contexts/AuthProvider';
import { Navigate, useLocation } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
    return (props) => {
        const { isAuthenticated } = useAuth();
        const location = useLocation();

        if (!isAuthenticated) {
            return <Navigate to="/login" state={{ from: location }} replace />;
        }

        return <WrappedComponent {...props} />;
    };
};

export default withAuth;
