import React, { useState } from 'react';
import { Container, Table, Button, Alert, Modal } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthProvider';
import { useUserListings, useDeleteListing } from '../../services/listingService';
import { Link, useNavigate } from 'react-router-dom';
import styles from './MyListings.module.css';

const MyListings = () => {
    const { user } = useAuth();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedListingId, setSelectedListingId] = useState(null);
    const navigate = useNavigate();

    const { data: listings = [], isLoading, error, refetch } = useUserListings(user.id);
    const deleteMutation = useDeleteListing();

    const handleDeleteClick = (listingId, status) => {
        if (status === 'completed') {
            alert('Completed listings cannot be deleted.');
            return;
        }
        setSelectedListingId(listingId);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteMutation.mutateAsync(selectedListingId);
            setShowDeleteModal(false);
            refetch(); // Refetch the listings after successful deletion
        } catch (err) {
            console.error('Failed to delete listing:', err);
        }
    };

    const handleEditClick = (listingId) => {
        navigate(`/edit-listing/${listingId}`);
    };

    if (isLoading) return <Container className="mt-5"><p>Loading listings...</p></Container>;

    return (
        <Container className="mt-5">
            <h2>My Listings</h2>
            {error && <Alert variant="danger">Failed to load listings. Please try again.</Alert>}
            {listings.length === 0 ? (
                <p>You haven't created any listings yet.</p>
            ) : (
                <Table striped bordered hover className={styles.responsiveTable}>
                    <thead>
                        <tr>
                            <th>Item Name</th>
                            <th>Price Indication</th>
                            <th>Status</th>
                            <th>Location</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listings.map(listing => (
                            <tr key={listing.id}>
                                <td data-label="Item Name">
                                    <Link to={`/listing/${listing.id}`}>{listing.item_name}</Link>
                                </td>
                                <td data-label="Price Indication">${listing.price_indication}</td>
                                <td data-label="Status">{listing.status}</td>
                                <td data-label="Location">{listing.suburb}, {listing.city}</td>
                                <td data-label="Actions">
                                    <div className={styles.actionButtons}>
                                        <Button
                                            variant="primary"
                                            size="sm"
                                            onClick={() => handleEditClick(listing.id)}
                                            disabled={listing.status === 'completed'}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleDeleteClick(listing.id, listing.status)}
                                            disabled={listing.status === 'completed'}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this listing? This action cannot be undone.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirm} disabled={deleteMutation.isLoading}>
                        {deleteMutation.isLoading ? 'Deleting...' : 'Delete'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default MyListings;