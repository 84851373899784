import React, { useState } from 'react';
import { Container, Table, Button, Alert, Modal } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthProvider';
import { useUserOffers, useDeleteOffer } from '../../services/offerService';
import { Link } from 'react-router-dom';
import styles from './MyOffers.module.css';

const MyOffers = () => {
    const { user } = useAuth();
    const { data: offers, isLoading, error, refetch } = useUserOffers(user.id);
    const deleteOfferMutation = useDeleteOffer();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedOfferId, setSelectedOfferId] = useState(null);

    const handleDeleteClick = (offerId) => {
        setSelectedOfferId(offerId);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteOfferMutation.mutateAsync(selectedOfferId);
            setShowDeleteModal(false);
            refetch(); // Refetch offers after successful deletion
        } catch (err) {
            console.error('Failed to delete offer:', err);
        }
    };

    if (isLoading) return <Container className="mt-5"><p>Loading offers...</p></Container>;

    return (
        <Container className="mt-5">
            <h2>My Offers</h2>
            {error && <Alert variant="danger">Failed to load offers. Please try again.</Alert>}
            {offers && offers.length === 0 ? (
                <p>You haven't made any offers yet.</p>
            ) : (
                <Table striped bordered hover className={styles.responsiveTable}>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {offers && offers.map(offer => (
                            <tr key={offer.id}>
                                <td data-label="Item">
                                    <Link to={`/listing/${offer.listing_id}`}>{offer.item_name}</Link>
                                </td>
                                <td data-label="Price">${offer.price}</td>
                                <td data-label="Status">{offer.status}</td>
                                <td data-label="Date">{new Date(offer.created_at).toLocaleDateString()}</td>
                                <td data-label="Actions">
                                    {offer.status === 'pending' && (
                                        <div className={styles.actionButtons}>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => handleDeleteClick(offer.id)}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this offer?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default MyOffers;