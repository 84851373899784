import React from 'react';

const ListingDescription = ({ description, priceRange, suburb, city }) => (
    <>
        <p className="mt-4">{description}</p>
        <h4>Desired Price: ${priceRange}</h4>
        <p>Location: {suburb}, {city}</p>
    </>
);

export default ListingDescription;
