import axios from 'axios';
import { refreshToken, isTokenExpired } from '../utils/authUtils';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true, // Ensure cookies are sent with requests
});

// Add a request interceptor
apiClient.interceptors.request.use(
    async (config) => {
        // Check if the request requires authentication
        if (config.requiresAuth !== false) { // Default to requiring auth unless explicitly set to false
            let token = localStorage.getItem('token');

            if (token && isTokenExpired(token)) {
                try {
                    token = await refreshToken(); // Attempt to refresh the token
                    if (token) {
                        localStorage.setItem('token', token); // Store the new token in localStorage
                    } else {
                        throw new Error('Token refresh failed');
                    }
                } catch (error) {
                    console.error('Token refresh failed:', error);
                    localStorage.removeItem('token');
                    // Instead of redirecting here, we'll throw an error to be handled by React Query
                    throw new Error('Authentication failed');
                }
            }

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // If the error is due to an expired token and we haven't tried to refresh yet
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const token = await refreshToken();
                if (token) {
                    localStorage.setItem('token', token);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    return apiClient(originalRequest);
                }
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                localStorage.removeItem('token');
                // Throw an error to be handled by React Query
                throw new Error('Authentication failed');
            }
        }

        // For other errors, just reject the promise
        return Promise.reject(error);
    }
);

export default apiClient;