import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const About = () => {
    return (
        <Container className="my-5">
            <Card className="border-0 shadow-sm">
                <Card.Header className="bg-primary text-white py-3">
                    <h1 className="mb-0">About Wantify</h1>
                </Card.Header>
                <Card.Body className="px-4 py-5">
                    <Row className="mb-4">
                        <Col>
                            <h2 className="mb-3">What is Wantify?</h2>
                            <p className="lead">
                                Wantify is a unique, free-to-use website where you can post about items or services you're looking for, and connect with people who can provide them.
                            </p>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col>
                            <h2 className="mb-3">How It Works</h2>
                            <ol className="list-unstyled">
                                <li className="mb-2"><strong>1. Post Your Wants:</strong> Tell us what you're looking for, whether it's a specific item or a service.</li>
                                <li className="mb-2"><strong>2. Receive Offers:</strong> People who have what you want can make offers directly to you.</li>
                                <li className="mb-2"><strong>3. Choose an Offer:</strong> If you like an offer, you can accept it.</li>
                                <li className="mb-2"><strong>4. Connect:</strong> Once an offer is accepted, we provide you with the email of the person who made the offer so you can get in touch.</li>
                            </ol>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h2 className="mb-3">Why Choose Wantify?</h2>
                            <ul className="list-unstyled">
                                <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i> <strong>It's Free:</strong> Wantify is completely free to use. No hidden charges, no transaction fees.</li>
                                <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i> <strong>Efficient:</strong> Instead of searching through listings, let people come to you with exactly what you need.</li>
                                <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i> <strong>Flexible:</strong> Whether you're looking for rare items, common goods, or specific services, Wantify can help.</li>
                                <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i> <strong>Community-Driven:</strong> Connect directly with people who have what you're looking for.</li>
                            </ul>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col>
                            <p className="lead text-center">
                                Join Wantify today and start finding exactly what you're looking for, or help others by offering what you have. It's that simple!
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default About;