import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ListingCard from './ListingCard';
import Categories from '../Categories';
import apiClient from '../../services/apiClient';
import 'bootstrap/dist/css/bootstrap.min.css';

const Listings = () => {
    const [listings, setListings] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [categoriesError, setCategoriesError] = useState('');

    const limit = 6;

    useEffect(() => {
        const fetchCategories = async () => {
            setCategoriesLoading(true);
            setCategoriesError('');
            try {
                const response = await apiClient.get('/categories');
                if (!response.data.error) {
                    setCategories(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
                setCategoriesError('Failed to load categories');
            } finally {
                setCategoriesLoading(false);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchListings = async () => {
            setIsLoading(true);
            try {
                const categoryParam = selectedCategory ? `&category=${selectedCategory}` : '';
                const response = await apiClient.get(`/listings?page=${currentPage}&limit=${limit}${categoryParam}`);
                if (!response.data.error) {
                    setListings(response.data.data);
                    setTotalPages(response.data.totalPages);
                }
            } catch (error) {
                console.error('Error fetching listings:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchListings();
    }, [currentPage, selectedCategory]);



    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleCategoryChange = (categoryId) => {
        setSelectedCategory(categoryId);
        setCurrentPage(1);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];

        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <Button
                    key={i}
                    variant={i === currentPage ? 'primary' : 'light'}
                    onClick={() => handlePageChange(i)}
                    className="mx-1"
                >
                    {i}
                </Button>
            );
        }

        return pageNumbers;
    };

    return (
        <Container className="mt-5">
            <h2>Wantify Listings</h2>
            <Categories
                categories={categories}
                onSelectCategory={handleCategoryChange}
                selectedCategory={selectedCategory}
                isLoading={categoriesLoading}
                error={categoriesError}
            />
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <Row>
                        {listings.map(listing => (
                            <Col xs={12} sm={6} md={4} key={listing.id} className="mb-4">
                                <ListingCard
                                    listing={listing}
                                    city={listing.city}
                                    suburb={listing.suburb}
                                />
                            </Col>
                        ))}
                    </Row>
                    <div className="d-flex justify-content-center mt-4">
                        <Button
                            variant="light"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </Button>
                        {renderPageNumbers()}
                        <Button
                            variant="light"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </Button>
                    </div>
                </>
            )}
        </Container>
    );
};

export default Listings;